/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Quicksand', sans-serif;
}

:root {
	--primary-color: #02418E;
	--hover-primary: #2F81E6;
	--secundary-color: #E59500;
	--hover-secundary: #D5A021;
	--background-light: #F2F2F2;
	--background-dark: #001F4D;
	--light-text: #FFFFFF;
	--black-text: #202022;
	--footer-color: #001F4D;
	--white-color: #FAFAFA;
}


.container {
	margin: 0 auto;
	max-width: 95rem;
}

.container-banner-all {
	min-height: 40vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	color: var(--white-color);
	padding: 2rem 0;
	font-size: 3.5rem;
	font-family: "Playwrite NO", cursive !important;
}

/********************/
/*       ALERT      */
/********************/

.container-alert {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: .5rem;
	gap: 2rem;
	top: 7.1rem;
	z-index: 900;
	left: 50%;
	transform: translateX(-50%);
	background-color: rgba(255, 255, 255, 0.5);
}

.correct-alert {
	color: #19C948;
}

.incorrect-alert {
	color: #D0293F;
}

.good {
	color: #19C948;
}

.bad {
	color: #D0293F;
}

/********************/
/*     WHATSAPP     */
/********************/

.whatsapp-button {
	position: fixed;
	bottom: 40px;
	right: 40px;
	display: flex;
	gap: 1rem;
	background-color: var(--primary-color);
	align-items: center;
	justify-content: center;
	padding: .5rem 1.5rem;
	color: var(--white-color);
	border-radius: 2rem;
	z-index: 10;
	transition: all .5s ease;
}

.whatsapp-button:hover {
	background-color: var(--hover-primary);
}

.whatsapp-icon {
	font-size: 2rem;
	display: flex;
	align-items: center;
}

/********************/
/*      SWIPER      */
/********************/

.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;

	/* Center slide text vertically */
	display: flex;
	justify-content: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.swiper-banner-home {
	padding: 15rem 0;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	justify-content: center;
	align-items: center;
	color: var(--white-color);
}

.swiper-banner-home h3 {
	font-size: 3rem;
	font-weight: 400;
}

.swiper-banner-home p {
	width: 40rem;
	height: 4rem;
}

.earring {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/banner-ring.webp');
	background-size: cover;
	background-position: center;
}

.necklace {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/banner-necklace.webp');
	background-size: cover;
	background-position: center;
}

.bracelet {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/banner-bracelet.webp');
	background-size: cover;
	background-position: center;
}

.link-shop-category {
	padding: .8rem 1.2rem;
	background-color: var(--white-color);
	transition: all .5s ease;
	color: var(--footer-color);
}

.link-shop-category:hover {
	background-color: var(--footer-color);
	color: var(--white-color);
}

/********************/
/*   CONTACT-FORM   */
/********************/

.container-submit-button {
	padding-top: 1rem;
}

.submit-contact-button {
	background-color: var(--footer-color);
	color: var(--white-color);
	border: none;
	outline: none;
	padding: .5rem 1rem;
	cursor: pointer;
	transition: all .5s ease;
	font-size: 1rem;
}

.submit-contact-button:hover {
	background-color: var(--primary-color);
}

.contain-contact-form {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding-bottom: 8rem;
}

.container-contact-divider {
	display: flex;
	gap: 2rem;
}

.input-contact {
	position: relative;
	height: 3rem;
	line-height: 3rem;
	width: 15rem;
}

.input-contact-msg {
	position: relative;
	width: 32rem;
	line-height: 3rem;
}

.input-contact-form {
	position: absolute;
	width: 100%;
	outline: none;
	font-size: 1.1rem;
	padding: 0 1rem;
	line-height: 3rem;
	border-radius: .5rem;
	border: 2px solid var(--footer-color);
	background: transparent;
	transition: all .2s ease;
	z-index: 10;
}

.label-line {
	position: absolute;
	font-size: 1rem;
	padding: 0 .5rem;
	margin: 0 1rem;
	transition: all .3s ease;
	background-color: var(--white-color);
}

.input-contact-form:focus,
.input-contact-form:valid {
	color: var(--primary-color);
	border: 3px solid var(--primary-color);
}

.input-contact-form:focus + .label-line,
.input-contact-form:valid + .label-line {
	color: var(--primary-color);
	height: 1.8rem;
	line-height: 1rem;
	padding: 0 .4rem;
	transform: scale(0.9) translate(-10px, -10px);
	transform: ;
	background-color: var(--white-color);
	z-index: 25;
}

.required-fields {
	font-size: .9rem;
}

/********************/
/*  SHIPPING-FORM   */
/********************/

.container-shipping-checkout {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 2rem 0;
}

.contain-shipping-checkout {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.container-shipping-form {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.container-form-coupon {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.text-select-option {
	color: #F00;
}

.input-shipping {
	position: relative;
	height: 3rem;
	line-height: 3rem;
	width: 22rem;
}

.checkout-text-confirm {
	padding: .5rem 1rem;
	background-color: #999;
	color: var(--white-color);
	text-align: center;
	border: none;
	cursor: unset;
	font-size: 1rem;
}

/********************/
/*       STARS      */
/********************/

.rating_stars {
	color: var(--footer-color);
	font-size: 1.7rem;
}

.rating-card-star {
	color: var(--secundary-color);
}

.empty-star {
	color: #AAA !important;
}

/********************/
/*  PRODUCT-IMAGES  */
/********************/

.container-product-images {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
	align-items: center;
}

.selected-image {
	width: 35rem;
	height: 35rem;
	background-color: #CCC;
	border: 1px solid #CCC;
}

.selected-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.container-mini-images {
	display: flex;
	gap: 1rem;
}

.mini-images {
	width: 8rem;
	height: 8rem;
	border: 1px solid #CCC;
}

.mini-images img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/********************/
/*    STAR-RATING   */
/********************/

.star-rating-container {
	display: flex;
	gap: .5rem;
}

.button-content-stars {
	border: none;
	outline: none;
	cursor: pointer;
}

.star-rating-button {
	font-size: 1.8rem;
	transition: all .3s ease;
}

.yellow-star {
	color: var(--secundary-color);
}

.gray-star {
	color: #aaa;
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	.container {
		width: 100%;
	}

	/* Banner-All */
	.container-banner-all {
		font-size: 3rem;
	}

	/* Alert */
	.container-alert { 
		font-size: .9rem;
	}
	
	/* Swiper */
	.swiper-banner-home {
		padding: 15rem 2rem;
	}

	.swiper-banner-home p {
		width: 100%;
		height: 8rem;
	}

	/* Contact-Form */
	.container-contact-divider {
		display: flex;
		flex-direction: column;

	}

	.input-contact {
		width: 100%;
	}

	.input-contact-msg {
		width: 100%;
	}

	/* Shipping-Form */
	.input-shipping {
		width: 100%;
	}

	/* PRODUCT-IMAGES */
	.selected-image {
		width: 20rem;
		height: 20rem;
	}
}
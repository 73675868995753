/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Quicksand', sans-serif;
}

:root {
	--primary-color: #02418E;
	--hover-primary: #2F81E6;
	--secundary-color: #E59500;
	--hover-secundary: #D5A021;
	--background-light: #F2F2F2;
	--background-dark: #001F4D;
	--light-text: #FFFFFF;
	--black-text: #202022;
	--footer-color: #001F4D;
	--white-color: #FAFAFA;
}


.container {
	margin: 0 auto;
	max-width: 95rem;
}

.container-banner-all {
	min-height: 40vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	color: var(--white-color);
	padding: 2rem 0;
	font-size: 3.5rem;
	font-family: "Playwrite NO", cursive !important;
}

.container-banner-all h3 {
	font-weight: 200 !important;
	text-transform: uppercase;
}

/********************/
/*       AUTH       */
/********************/

/* Banner-All */
.auth {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/banner-auth.webp');
	background-position: center;
	background-size: cover;
}

.container-form {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.form-content {
	position: relative;
	width: 100%;
	height: 60vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.form-content-signup {
	position: relative;
	width: 100%;
	height: 80vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.contain-form {
	z-index: 10;
	width: 30rem;
	padding: 2rem;
	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 1.6rem;
	align-items: center;
}

.contain-img-auth {
	width: 15rem;
}

.input-auth {
	position: relative;
	height: 3rem;
	line-height: 3rem;
	width: 22rem;
}

.button-form {
	font-size: 1rem;
	padding: .8rem 1.2rem;
	cursor: pointer;
	border: none;
	outline: none;
	color: var(--white-color);
	background-color: var(--footer-color);
	transition: all .5s ease;
}

.button-form:hover {
	background-color: var(--primary-color);
}

.change-login-signup {
	color: var(--footer-color);
	display: flex;
	gap: .5rem;
}

.link-change {
	color: var(--footer-color);
	font-weight: 600;
	transition: all .5s ease;
}

.link-change:hover {
	color: var(--hover-primary);
}

.password-show-hide {
	position: absolute;
	top: 1rem;
	font-size: 1.2rem;
	right: 1rem;
	z-index: 10;
	transition: all .7s ease;
	color: var(--primary-color);
	opacity: 0;
	cursor: pointer;
}

.input-auth input:valid ~ .password-show-hide,
.input-auth input:focus ~ .password-show-hide {
	opacity: 1;
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	/* Banner-All */
	.container-banner-all {
		font-size: 3rem;
	}

	.contain-form {
		width: 100%;
	}
}
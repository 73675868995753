/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Quicksand', sans-serif;
}

:root {
	--primary-color: #02418E;
	--hover-primary: #2F81E6;
	--secundary-color: #E59500;
	--hover-secundary: #D5A021;
	--background-light: #F2F2F2;
	--background-dark: #001F4D;
	--light-text: #FFFFFF;
	--black-text: #202022;
	--footer-color: #001F4D;
	--white-color: #FAFAFA;
}


.container {
	margin: 0 auto;
	max-width: 95rem;
}

.container-banner-all {
	min-height: 40vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	color: var(--white-color);
	padding: 2rem 0;
	font-size: 3.5rem;
	font-family: "Playwrite NO", cursive !important;
}

/********************/
/*       CART       */
/********************/

/* Banner-All */
.cart {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/banner-cart.webp');
	background-position: center;
	background-size: cover;
	position: fixed;
	width: 100%;
	z-index: 30;
}

.container-dashboard-cart-items {
	position: fixed;
	width: 20rem;
	height: 100vh;
	display: flex;
	flex-direction: column;
	padding: 40vh 0 1rem;
	min-height: 46vh;
	border-right: 1px solid #ccc;
}

.container-all-cart {
	overflow: scroll;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 40vh;
}

.container-items-cart {
	padding: 0 2rem;
}

.checkout-items {
	position: fixed;
	bottom: 0;
	background-color: var(--background-light);
	z-index: 2;
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 2rem 20rem;
	max-height:  20vh;
}

.container-checkout-footer {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 0 1rem;
}

.checkout-item {
	display: flex;
	gap: 5rem;
	justify-content: space-between;
}

.checkout-item-form {
	display: flex;
	gap: 2rem;
	justify-content: space-between;
}

.contain-checkout-button {
	display: flex;
	justify-content: flex-end;
}

.checkout-button {
	padding: .5rem 1rem;
	background-color: var(--footer-color);
	color: var(--white-color);
	text-align: center;
	transition: all 0.5s ease;
	border: none;
	outline: none;
	cursor: pointer;
	font-size: 1rem;
}

.checkout-button:hover {
	background-color: var(--primary-color);
}

.link-back-to-shop {
	display: flex;
	align-items: center;
	gap: .5rem;
	color: var(--black-text);
	font-weight: 600;
	transition: all 0.3s ease;
}

.link-back-to-shop:hover {
	color: var(--primary-color);
}

.container-empty-cart {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 2rem;
	padding: 3rem 0;
}

/********************/
/*    CHECKOUT      */
/********************/

.container-shipping-options {
	display: flex;
	flex-direction: column;
	gap: .5rem;
}

.contain-shipping-options {
	display: grid;
	grid-template-columns: 2fr 1fr 1fr;
	grid-gap: 1rem;
}

.container-shipping-select {
	display: flex;
	gap: .5rem;
}

.checkout-text {
	padding: .5rem 1rem;
	background-color: var(--hover-primary);
	color: var(--white-color);
	text-align: center;
	border: none;
	outline: none;
	font-size: 1rem;
}

.container-cart-checkout {
	display: flex;
	gap: 2rem;
}

.shipping-items {
	display: flex;
	flex-direction: column;
	gap: .5rem;
}

.shipping-item {
	display: flex;
	gap: 1rem;
}

/********************/
/*    CART-ITEM     */
/********************/

.container-cart-item {
	padding: 1rem 2rem;
	display: grid;
	grid-template-columns: repeat(6, 1fr);
}

.container-checkout-item {
	padding: 1rem 2rem;
	grid-gap: 2rem;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
}

.item-back {
	background-color: var(--background-light);
}

.container-count {
	margin-left: 2rem;
}

.container-cart-item h4 {
	font-size: 1.1rem;
}

.cart-item-image {
	width: 12rem;
	height: 12rem;
}

.cart-item-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.cart-item-price {
	font-weight: 600;
}

.button-remove {
	border: none;
	outline: none;
	background-color: transparent;
	font-size: 2.2rem;
	cursor: pointer;
	transition: all .5s ease;
}

.button-remove:hover {
	color: var(--primary-color);
}

.container-form-count {
	display: flex;
	align-items: flex-start;
	gap: .5rem;
}

.select-count {
	display: flex;
	justify-content: center;
	font-size: 1rem;
	font-weight: 500;
	width: 3rem;
	height: 1.8rem;
	align-items: center;
	outline: none;
	border: 1px solid #000;
	cursor: pointer;
	border-radius: .3rem;
}

.update-button {
	display: flex;
	align-items: center;
	border: none;
	font-size: 1.5rem;
	font-weight: 500;
	background-color: transparent;
	cursor: pointer;
	transition: all .5s ease;
}

.update-button:hover {
	color: var(--hover-primary);
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	/* Banner-All */
	.container-banner-all {
		font-size: 3rem;
	}

	/* Cart */
	.container-all-cart {
		margin-right: 2.5rem;
	}

	.container-dashboard-cart-items {
		width: 4.5rem;
		padding: 40vh 0 1rem;
	}

	.container-cart-items {
		display: flex;
		flex-direction: column;
		gap: 2rem;
	}

	.checkout-items {
		padding: 1rem;
		font-size: .9rem;
		flex-direction: column-reverse;
		gap: 1rem;
	}

	.checkout-item {
		gap: 1rem;
	}

	.container-checkout-footer {
		gap: .5rem;
	}

	.container-cart-checkout {
		flex-direction: column;
	}

	/* Cart-Item */
	.container-cart-item {
		display: flex;
		flex-direction: column;
		gap: .5rem;
		padding: .5rem;
		position: relative;
		justify-content: center;
		align-items: center;
		font-size: .9rem;
		width: 12rem;
	}

	.container-cart-item h4 {
		font-size: 1rem;
		height: 2.2rem;
	}

	.cart-remove-contain {
		position: absolute;
		right: .9rem;
		top: .2rem;
	}

	.container-form-count {
		gap: 3rem;
		align-items: center;
	}

	.cart-item-image {
		width: 10rem;
		height: 10rem;
	}

	/* Checkout-Item */
	.container-checkout-item {
		display: flex;
		flex-direction: column;
		gap: .5rem;
		justify-content: center;
		align-items: center;
	}

	.phone-titles-cart {
		display: none;
	}
}
/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Quicksand', sans-serif;
}

:root {
	--primary-color: #02418E;
	--hover-primary: #2F81E6;
	--secundary-color: #E59500;
	--hover-secundary: #D5A021;
	--background-light: #F2F2F2;
	--background-dark: #001F4D;
	--light-text: #FFFFFF;
	--black-text: #202022;
	--footer-color: #001F4D;
	--white-color: #FAFAFA;
}


.container {
	margin: 0 auto;
	max-width: 95rem;
}

.container-banner-all {
	min-height: 40vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	color: var(--white-color);
	padding: 2rem 0;
	font-size: 3.5rem;
	font-family: "Playwrite NO", cursive !important;
}

/********************/
/*     HOME-CARD    */
/********************/
.container-home-card {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
	align-items: center;
	width: 18rem;
	transition: all .5s ease;
	border: 1px solid transparent;
	padding: 1rem;
}

.container-home-card:hover {
	border: 1px solid #AAA;
	box-shadow: 0px 0px 15px 0px rgba(69,69,69,0.75);
	-webkit-box-shadow: 0px 0px 15px 0px rgba(69,69,69,0.75);
	-moz-box-shadow: 0px 0px 15px 0px rgba(69,69,69,0.75);
}

.home-card-image {
	height: 18rem;
	width: 100%;
}

.home-card-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	overflow: hidden;
}

.home-card-title {
	color: var(--footer-color);
	transition: all .5s ease;
}

.home-card-info {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	justify-content: center;
	align-items: center;
}

.home-card-title:hover {
	color: var(--primary-color);
}

/********************/
/*    WISH-CARD     */
/********************/

.container-wish-product-card {
	display: flex;
	flex-direction: column;
	padding: .5rem;
	width: 13rem;
	position: relative;
	
}

.container-wish-product {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	text-align: center;
}

.wish-product-info {
	height: 3rem;
}

.wish-card-link {
	color: var(--footer-color);
	transition: all .5s ease;
	font-weight: 600;
}

.wish-card-link:hover {
	color: var(--primary-color);
}

.wish-product-image {
	width: 100%;
	padding: 1rem;
	height: 15rem;
	overflow: hidden;
	background-color: var(--background-light);
}

.wish-product-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: all .3s ease;
}

.wish-product-image img:hover {
	transform: scale(1.1);
}

.button-remove-contain {
	position: absolute;
	z-index: 2;
	top: .5rem;
	right: .5rem;
}

.remove-favorite {
	border: none;
	background-color: transparent;
	font-size: 1.5rem;
	cursor: pointer;
	color: var(--footer-color);
	transition: all .5s ease;
}

.remove-favorite:hover {
	color: var(--primary-color);
}

/********************/
/*    ORDER-CARD    */
/********************/

.container-order-product-card {
	display: flex;
	flex-direction: column;
	gap: 2.5rem;
	padding: 2rem;
	color: #000;
}

.container-header-order-card {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid #ccc;
}

.container-header-order-card h3 {
	font-size: 1.8rem;
}

.contain-transaction-id {
	display: flex;
	flex-direction: column;
	gap: .2rem;
}

.transaction-title {
	font-weight: 600;
}

.transaction-id-text {
	font-size: .8rem;
}

.container-number-order {
	display: flex;
	gap: 3rem;
}

.order-status-date span,
.container-number-order span,
.date-text {
	font-weight: 600;
}

.container-addres-info {
	display: flex;
	gap: 10rem;
}

.container-addres-text {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.container-addres-text h4 {
	font-size: 1.1rem;
}

.container-order-product {
	display: flex;
	flex-direction: column;
	gap: .5rem;
}

.order-progres-bar {
	background-color: #ddd;
	border-radius: .5rem;
	overflow: hidden;
}

.order-progres-bar-inner {
	width: calc(var(--step) * 25%);
	background-color: #009A2E;
	height: .5rem;
	border-radius: .5rem;
}

.container-text-bar-progress {
	display: flex;
	gap: 3rem;
	justify-content: space-between;
}

.text-bar-progress {
	font-weight: 500;
	padding: .5rem;
	border-radius: 1rem;
}

.text-bar-progress.active-text {
	background-color: var(--background-light);
	color: #009A2E;
}

.order-status-date {
	display: flex;
	justify-content: space-between;
}

/********************/
/*     SHOP-CARD    */
/********************/

.container-list-card {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 14rem;
	position: relative;
	overflow: hidden;
}

.container-discount {
	position: absolute;
	top: -.4rem;
	left: -2.5rem;
}

.discount-text {
	color: var(--white-color);
	background-color: var(--primary-color);
	padding: 1rem 3rem;
	transform: rotate(-45deg);
	font-weight: 500;
}

.container-list-card:hover .list-card-image {
	background-color: #D1D1D1;
}

.list-card-image {
	width: 100%;
	height: 16rem;
	overflow: hidden;
	position: relative;
	background-color: var(--background-light);
	transition: all .5s ease;
}

.list-image-icon {
	display: flex;
	flex-direction: column;
	position: absolute;
	z-index: 100;
	top: 0;
	right: 0;
	font-size: 2rem;
	gap: .5rem;
	opacity: 0;
	transition: all .3s ease;
	cursor: pointer;
}

.list-card-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: all .3s ease;
}

.list-card-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: .5rem;
	padding-top: .5rem;
	padding-bottom: 1rem;
}

.list-card-image img:hover {
	transform: scale(1.1);
}

.container-list-card:hover .list-image-icon {
	opacity: 1;
}

.list-card-price {
	font-size: .9rem;
	transition: all .5s ease;
}

.list-card-title {
	color: var(--black-text);
	height: 3rem;
	text-align: center;
}

.stocked-product {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	color: var(--white-color);
	z-index: 200;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all .5 ease;
}

.stocked-text {
	padding: .2rem;
	opacity: 0;
	transition: all .5 ease;
	font-weight: 600;
}

.container-list-card:hover .stocked-text {
	opacity: 1;
}

.container-price-bag {
	position: relative;
	display: flex;
	gap: .5rem;
}

.button-bag {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	border: none;
	background-color: transparent;
	font-size: 1.6rem;
	cursor: pointer;
	transition: all .5s ease;
	opacity: 0;
	left: -1rem;
}

.button-bag:hover {
	color: var(--primary-color);
}

.container-list-card:hover .stock-card-price {
	transform: translateX(2rem);
}

.container-list-card:hover .button-bag {
	opacity: 1;
}

/********************/
/*   RELATED-CARD   */
/********************/

.container-related-card {
	display: flex;
	flex-direction: column;
	width: 18rem;
	background-color: var(--white-color);
	padding: 1rem;
	gap: 1rem;
}

.card-image {
	width: 100%;
	height: 18rem;
	overflow: hidden;
}

.card-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: all .3s ease;
}

.card-image img:hover {
	transform: scale(1.1);
}

.related-card-title {
	color: var(--footer-color);
	font-weight: 600;
	transition: all .5s ease;
	height: 2.5rem;
	text-align: center;
}

.related-card-title:hover {
	color: var(--primary-color);
}

.related-card-info {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	align-items: center;
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	/* Banner-All */
	.container-banner-all {
		font-size: 3rem;
	}

	/* WISH-CARD */
	.container-wish-product-card {
		width: 8rem;
	}

	.wish-product-image {
		height: 10rem;
	}

	/* ORDER-CARD */
	.container-order-product-card {
		padding: 2rem 0;
	}

	.container-addres-text h4 {
		height: 2.2rem;
	}

	.container-addres-info {
		gap: 2rem;
	}

	.container-text-bar-progress {
		display: none;
	}

	/* SHOP-CARD */
	.container-list-card {
		width: 12rem;
	}

	.list-card-title {
		height: 2.2rem;
		text-align: center;
	}

	/* RELATED-CARD */
	.container-related-card {
		width: 12rem;
	}

	.card-image {
		height: 12rem;
	}
}

@media screen and (max-width: 400px) {
	.container-list-card {
		width: 10rem;
	}
}
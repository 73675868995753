/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Quicksand', sans-serif;
}

:root {
	--primary-color: #02418E;
	--hover-primary: #2F81E6;
	--secundary-color: #E59500;
	--hover-secundary: #D5A021;
	--background-light: #F2F2F2;
	--background-dark: #001F4D;
	--light-text: #FFFFFF;
	--black-text: #202022;
	--footer-color: #001F4D;
	--white-color: #FAFAFA;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

.container-banner-all {
	min-height: 40vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	color: var(--white-color);
	padding: 2rem 0;
	font-size: 3.5rem;
	font-family: "Playwrite NO", cursive !important;
}

::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: var(--footer-color);
}

/********************/
/*      NAVBAR      */
/********************/

.container-nav {
	position: fixed;
	top: 0;
	z-index: 1000;
	width: 100%;
	background-color: transparent;
	transition: background-color 0.5s ease-in-out;
}

.container-nav-phone {
	display: none;
}

.container-nav.scrolled {
	background-color: rgba(0, 0, 0, 0.6);
}

.phone-navbar-navigation {
	display: none;
}

.container-navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.5rem 15rem;
	color: var(--white-color);
}

.container-logo {
	color: var(--light-text);
	font-size: 1.4rem;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.container-logo img {
	width: 15rem;
}

.container-navlinks {
	display: flex;
	gap: 2rem;
}

.navlinks-icon-link {
	color: var(--white-color);
	font-size: 1.4rem;
	transition: all .5s ease;
	position: relative;
}

.navlinks-icon-link span {
	position: absolute;
	font-size: .9rem;
	background-color: var(--white-color);
	color: #555;
	font-weight: 500;
	padding: .05rem .4rem;
	border-radius: 50%;
	top: -.5rem;
}

.navlinks-icon-link:hover {
	transform: scale(1.4);
}

.navlink-social {
	display: flex;
	gap: 2rem;
}

.navlink-item {
	position: relative;
	color: var(--text-color);
	padding: .5rem 1rem;
	border-radius: .8rem;
	transition: all .5s ease;
	font-weight: 500;
}

.navlink-item:hover {
	color: var(--hover-primary);
}

.active {
	color: var(--secundary-color);
}

.social-active {
	background-color: var(--secundary-color);
}

.container-navbar-navigation {
	display: flex;
	padding: .5rem 15rem;
	justify-content: center;
	gap: 4rem;
	border-bottom: 1px solid var(--white-color);
}

.container-nav.scrolled .container-navbar-navigation {
	border-bottom: 1px solid rgba(0, 0, 0, 0.6);
}

.navbar-navigation {
	color: var(--white-color);
	font-size: 1.1rem;
}

.category-link {
	text-transform: capitalize;
	color: var(--black-text);
	font-weight: 400;
}

#menu-toggle {
	display: none;
	background: none;
	border: none;
	cursor: pointer;
	padding: 0;
}

.line {
	background-color: var(--white-color);
	display: block;
	width: 25px;
	height: 2px;
	margin: 5px 0;
	border-radius: 50%;
	transition: all .3s ease-out;
}

.line-1 {
	transform: rotate(45deg) translate(0.6rem);
}

.line-2 {
	opacity: 0;
}

.line-3 {
	transform: rotate(-45deg) translate(0.6rem);
}

/* SearchBox */
.container-searchbox {
	position: relative;
	min-width: 1.5rem;
}

.container-searchbox input {
	border: none;
	outline: none;
	background-color: transparent;
	transition: all .5s ease;
	border-bottom: 2px solid transparent;
	color: var(--white-color);
	padding: .2rem .5rem;
	font-size: 1rem;
	position: absolute;
	left: -100%;
	opacity: 0;
}

.searchbox-submit {
	left: 0;
	position: absolute;
	transition: all .5s ease;
	color: var(--white-color);
	outline: none;
	border: none;
	background-color: transparent;
	font-size: 1.5rem;
}

.container-searchbox:hover .searchbox-submit {
	left: 12rem;
}

.container-searchbox:hover input {
	border-bottom: 2px solid var(--white-color);
	left: calc(100% - 2rem);
	opacity: 1;
}

.container-searchbox input:valid,
.container-searchbox input:focus {
	border-bottom: 2px solid var(--white-color);
}

/********************/
/*      FOOTER      */
/********************/

.container-footer {
	display: flex;
	flex-direction: column;
}

.container-footer-info {
	display: flex;
	padding: 2rem 8rem;
	justify-content: space-around;
	border-top: 1px solid #DDD;
}

.container-footer-links {
	display: flex;
	flex-direction: column;
	gap: .7rem;
}

.container-logo-footer {
	width: 12rem;
}

.container-footer-rights {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	background-color: var(--background-light);
	color: var(--dark-text);
}

.footer-subtitle {
	color: var(--footer-color);
	font-weight: 400;
	font-size: 1.1rem;
	padding-bottom: .5rem;
}

.contain-footer-info {
	display: flex;
	flex-direction: column;
	gap: .7rem;
	border-left: 1px solid #DDD;
	padding: 0 3rem;
}

.footer-links {
	color: var(--footer-color);
	transition: all .3s ease;
	font-weight: 500;
}

.footer-links:hover {
	color: var(--hover-primary);
}

.contain-logo {
	font-size: 1.4rem;
	color: var(--footer-color);
}

.footer-contact-item {
	display: flex;
	gap: .6rem;
	font-size: 1rem;
	color: var(--footer-color);
}

.icon-footer {
 	color: var(--footer-color);
}

.contain-footer-social {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: .8rem;
	font-size: 1.5rem;
}

.social-footer-links {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: .5rem;
	background-color: var(--footer-color);
	color: var(--light-text);
	transition: all .5s ease;
}

.social-footer-links:hover {
	transform: scale(1.5);
	color: var(--footer-color);
	background-color: transparent;
}

.promotions-input-link {
	width: 100%;
	display: flex;
	align-items: center;
}

.input-promotions {
	padding: .8rem 1rem;
	outline: none;
	border: 2px solid var(--footer-color);
	transition: all .5s ease;
}

.input-promotions:focus {
	border: 2px solid var(--primary-color);
}

.link-promotions {
	padding: .65rem;
	display: flex;
	align-items: center;
	background-color: var(--footer-color);
	color: var(--white-color);
	font-size: 1.5rem;
	transition: all .5s ease;
}

.link-promotions:hover {
	background-color: var(--primary-color);
}

 /********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	/* Banner-All */
	.container-banner-all {
		font-size: 3rem;
		text-align: center;
	}

	/* Navbar */
	.container-navbar {
		display: none;
	}

	#menu-toggle {
		display: block;
	}

	.container-nav-phone {
		background-color: var(--footer-color);
		display: flex;
		gap: 2rem;
		padding: 1rem 1rem;
		align-items: center;
		justify-content: space-between;
		position: fixed;
		top: 0;
		z-index: 2000;
		width: 100%;
	}

	.navlinks-icon-link {
		color: var(--white-color);
	}

	.container-navphone-img {
		width: 10rem;
	}

	.container-navphone-img img {
		width: 100%;
	}

	.contain-nav-phone {
		display: flex;
		gap: 2rem;
	}

	.navigation-phone {
		display: flex;
		gap: 1.5rem;
		color: var(--white-color);
		align-items: center;
		font-size: 1.4rem;
	}

	.container-navbar-navigation {
		display: none;
	}
	
	.navbar {
		display: flex;
		gap: 2.5rem;
		background-color: var(--footer-color);
		flex-direction: column;
		position: absolute;
		left: -500px;
		top: 60px;
		width: 80%;
		align-items: flex-start;
		padding: 2rem 3rem;
		transition: all .5s ease;
		visibility: hidden;
		z-index: 2;
		border: none;
		height: 100vh;
	}

	.navbar.menu-active {
		visibility: visible;
		transform: translateX(500px);
	}

	/* Footer */
	.container-footer-info {
		flex-direction: column;
		width: 100%;
		padding: 2rem;
		justify-content: center;
		align-items: center;
		gap: 2rem;
	}

	.contain-footer-info {
		border: none;
		justify-content: center;
		align-items: center;
		padding: 0;
	}

	.container-footer-links {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}

	.footer-subtitle {
		font-weight: 500;
	}

	::-webkit-scrollbar {
		height: 5px;
	}
}
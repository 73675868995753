/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Quicksand', sans-serif;
}

:root {
	--primary-color: #02418E;
	--hover-primary: #2F81E6;
	--secundary-color: #E59500;
	--hover-secundary: #D5A021;
	--background-light: #F2F2F2;
	--background-dark: #001F4D;
	--light-text: #FFFFFF;
	--black-text: #202022;
	--footer-color: #001F4D;
	--white-color: #FAFAFA;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

.container-banner-all {
	min-height: 40vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	color: var(--white-color);
	padding: 2rem 0;
	font-size: 3.5rem;
	font-family: "Playwrite NO", cursive !important;
	font-weight: 200 !important;
}

/********************/
/*       HOME       */
/********************/

/* Banner-All */
.home {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/banner-main.webp');
	background-position: center;
	background-size: cover;
	display: flex;
	justify-content: center !important;
	color: #ddd !important;
}

.container-banner {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 71vh;
}

.button-main-banner {
	text-transform: uppercase;
	background-color: var(--white-color);
	color: var(--black-text);
	padding: .5rem 1rem;
	font-weight: 600;
	transition: all .5s ease;
	font-size: 2rem;
	margin-top: 8rem;
}

.button-main-banner:hover {
	background-color: var(--black-text);
	color: var(--white-color);
}

/* Gold */
.container-home-gold {
	background:  url('../images/banner-gold.webp');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding: 10rem 15rem;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

.contain-home-gold {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding: 0 4rem;
}

.contain-home-gold h3 {
	font-size: 3rem;
	font-weight: 400;
}

.contain-home-gold p {
	font-size: 1.3rem;
}

.link-to-cite {
	background-color: var(--white-color);
	font-size: 1rem;
	color: var(--footer-color);
	padding: .8rem 1.2rem;
	transition: all .5s ease;
	display: flex;
	align-items: center;
	width: 9rem;
	justify-content: center;
}

.link-to-cite:hover {
	background-color: var(--footer-color);
	color: var(--white-color);
}

/* Caracteristics */
.container-caracteristics {
	display: flex;
	justify-content: space-around;
	padding: 8rem 10rem;
	gap: 1.5rem;
}

.caracteristics-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
}

.caracteristics-item-img {
	width: 5rem;
}

.caracteristics-item-img img {
	width: 100%;
}

.caracteristics-item-text {
	width: 14rem;
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: .6rem;
}

/* Toggle-Products */
.container-toggle-products {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	padding: 5rem;
	align-items: center;
	justify-content: center;
}

.contain-toggle-home {
	display: flex;
	gap: 1rem;
}

.contain-products-toggle {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
}

.toggle-products-home {
	border: none;
	outline: none;
	cursor: pointer;
	padding: .5rem 1rem;
	font-size: 1rem;
	transition: all .5s ease;
}

.toggle-active {
	background-color: var(--footer-color);
	color: var(--white-color);
}

.toggle-products-home:hover {
	background-color: var(--footer-color);
	color: var(--white-color);
}

.container-featured {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2rem;
	padding: 3rem 0;
}

.featured-product-card {
	display: flex;
	gap: 2rem;
}

.contain-featured {
	display: flex;
	gap: 2.5rem;
}

.container-maincategories {
	display: flex;
	justify-content: center;
	align-items: center;
}

.main-category {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid var(--black-text);
	width: 18rem;
	height: 18rem;
}

/* Grid */
.container-home-grid {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	padding: 5rem 12rem;
	background-color: var(--white-color);
	grid-gap: 1.5rem;
}

.contain-home-grid-h1 {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/grid-h1.webp');
	background-size: cover;
	background-position: center;
	text-align: center;
}

.contain-home-grid-h2 {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/grid-h2.webp');
	background-size: cover;
	background-position: center;
	text-align: center;
}

.contain-home-grid-v {
	background: url('../images/grid-v.webp');
	background-size: cover;
	background-position: center;
	grid-row: 1/3;
	grid-column: 2;
	text-align: center;
}

.home-grid-content {
	color: var(--white-color);
	padding: 6rem 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 26rem;
	gap: 1rem;
}

.home-grid-content h3 {
	font-size: 2rem;
	font-weight: 400;
}

.home-grid-content-v {
	color: var(--white-color);
	padding: 6rem 2rem;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	width: 26rem;
	gap: 1rem;
}

.home-grid-content-v h3 {
	font-size: 2rem;
	font-weight: 400;
}

.links-grid-home {
	background-color: var(--white-color);
	font-size: 1rem;
	color: var(--footer-color);
	padding: .8rem 1.2rem;
	transition: all .5s ease;
}

.links-grid-home:hover {
	background-color: var(--footer-color);
	color: var(--white-color);
}

/* Swiper */

/* Mensual-Promo */
.container-mensual-promo {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8rem 2rem;
	gap: 3rem;
	height: 100vh;
}

.contain-mensual-promo {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.6);
	padding: 2rem;
	border-radius: 2rem;
	max-width: 40rem;
	width: 100%;
}

.contain-mensual-promo h3 {
	font-size: 3rem;
	font-weight: 400;
	color: var(--primary-color);
	font-family: "Dancing Script", cursive !important;
}

.contain-mensual-promo p {
	font-size: 1.1rem;
	text-align: center;
}

.link-to-shop {
	padding: .8rem 1.2rem;
	background-color: var(--footer-color);
	color: var(--white-color);
	transition: all .5s ease;
	font-size: 1rem;
}

.link-to-shop:hover {
	background-color: var(--primary-color);
}

.mensual-promo-img {
	width: 40rem;
}

.mensual-promo-img img {
	width: 100%;
}

/* Presents */
.container-home-presents {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 5rem 12rem;
	gap: 5rem;
}

.contain-home-presents {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 3rem;
	padding: 0 3rem;
	text-align: center;
}

.contain-home-presents h3 {
	font-size: 2.5rem;
	font-weight: 400;
}

.container-presents-img {
	width: 40rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.container-presents-img img {
	width: 100%;
}

/********************/
/*       ABOUT      */
/********************/

/* Banner-All */
.about {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/banner-about.webp');
	background-position: center;
	background-size: cover;
}

/* Description */
.container-about-description {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 5rem 15rem;
	gap: 3rem;
}

.about-description-img {
	width: 40rem;
}

.about-description-img img {
	width: 100%;
}

.contain-about-description {
	font-size: 1.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 3rem;
}

/* Characteristics */
.container-about-characteristics {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	padding: 5rem 12rem;
	align-items: center;
	justify-content: center;
}

.contain-about-characteristics {
	display: flex;
	gap: 2rem;
}

.characteristics-about-box {
	width: 25rem;
	background-color: var(--background-light);
	padding: 2rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
	align-items: center;
	color: var(--footer-color);
	transition: all .5s ease;
}

.characteristics-about-box p {
	text-align: center;
}

.characteristics-about-box:hover {
	background-color: var(--footer-color);
	color: var(--white-color);
}

.icon-about-characteristics {
	font-size: 3rem;
}

/* Horizontal */
.container-about-horizontal {
	display: flex;
	padding: 15rem;
	background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../images/banner-about1.webp');
	background-position: center;
	background-size: cover;
	justify-content: center;
	align-items: center;
}

.container-about-horizontal p {
	font-size: 1.5rem;
	color: var(--white-color);
	width: 70rem;
	text-align: center;
}

/* Contact-About */
.container-about-contact {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding: 5rem;
}

.container-about-contact p {
	text-align: center;
	padding: 0 15rem;
	font-size: 1.2rem;
}

.container-maps-about {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	padding: 2rem 0;
	justify-content: center;
	align-items: center;
}

.container-maps-about h3 {
	font-size: 2rem;
	text-transform: uppercase;
	font-weight: 400;
}

.container-maps-about div {
	width: 100vh;
}

/********************/
/*     POLICIES     */
/********************/

/* Banner-All */
.policies {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/banner-policies.webp');
	background-position: center;
	background-size: cover;
}

.container-policies {
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	padding: 5rem 8rem;
}

.container-policies p {
	line-height: 1.7rem;
}

.subtitle-policies {
	font-weight: 600;
	gap: .2rem;
}

.subtitle-policies span{
	font-weight: 400;
}

.icon-policies {
	padding-right: .4rem;
	font-size: .8rem;
}

/********************/
/*      CONTACT     */
/********************/

/* Banner-All */
.contact {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/banner-contact.webp');
	background-position: center;
	background-size: cover;
}

/* Boxes */
.container-boxes-contact {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 2rem;
	padding: 5rem 0;
}

.contain-boxes-contact {
	position: relative;
	background-color: var(--footer-color);
	width: 23rem;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	color: var(--white-color);
	align-items: center;
	padding: 2.5rem 2rem;
	border-radius: .5rem;
}

.contain-boxes-contact h3 {
	font-size: 1.4rem;
}

.contain-boxes-contact p {
	font-size: .9rem;
}

.box-contact-icon {
	position: absolute;
	top: -1.8rem;
	color: var(--footer-color);
	font-size: 2rem;
	background-color: var(--secundary-color);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: .5rem;
	height: 3.5rem;
	width: 3.5rem;
}

/* Maps-Form */
.container-maps-form {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 8rem 15rem;
	gap: 2rem;
}

/* Social-Media */
.container-contact-social-media {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 4rem;
	gap: 2rem;
}

.container-contact-social-media h3 {
	font-size: 2rem;
	font-weight: 400;
}

.container-contact-social {
	display: flex;
	gap: 3rem;
}

.social-contact-links {
	width: 3.5rem;
	height: 3.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--footer-color);
	color: var(--white-color);
	font-size: 2rem;
	transition: all .3s ease;
}

.social-contact-links:hover {
	transform: scale(1.5);
	color: var(--footer-color);
	background-color: transparent;
}

/* Brand-Color */
.facebook:hover {
	color: #1877F2 !important;
}

.instagram:hover {
 	color: #DD2A7B !important;
}

.whatsapp:hover {
	color: #25D366 !important;
}

/********************/
/*       DATES      */
/********************/

.container-dates-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2rem;
	padding: 5rem;
}

.contain-dates-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2rem;
	padding: 5rem;
}

.submit-date-button {
	background-color: var(--footer-color);
	color: var(--white-color);
	border: none;
	outline: none;
	padding: .5rem 1rem;
	cursor: pointer;
	transition: all .5s ease;
	font-size: 1rem;
}

.submit-date-button:hover {
	background-color: var(--primary-color);
}

.container-checkbox-dates {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.contain-checkbox-dates {
	display: flex;
	flex-direction: column;
	gap: .5rem;
}

.checkbox-dates {
	display: flex;
	gap: 1rem;
}

.contain-date-time {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	justify-content: center;
	align-items: center;
	width: 22rem;
	height: 3rem;
}

.date-time-select {
	width: 100%;
	padding: .7rem 1rem;
	font-size: 1.1rem;
	line-height: 3rem;
	border-radius: .5rem;
	border: 2px solid var(--footer-color);
}

/********************/
/*   PHONE-SCREEN   */
/********************/
@media screen and (max-width: 1600px) {
	/* HOME */
	.mensual-promo-img {
		width: 30rem;
	}

	/* ABOUT */
	.container-about-description {
		padding: 5rem;
	}

	.about-description-img {
		width: 30rem;
	}
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	/* Banner-All */
	.container-banner-all {
		font-size: 3rem;
	}

	/* HOME */
	/* Characteristics */
	.container-caracteristics {
		display: flex;
		flex-direction: column;
		padding: 5rem 2rem;
		gap: 3rem;
		justify-content: center;
		align-items: center;
	}

	/* Gold */
	.container-home-gold {
		display: flex;
		padding: 5rem 2rem;
		flex-direction: column;
		text-align: center;
		justify-content: center;
		align-items: center;
	}

	.contain-home-gold {
		justify-content: center;
		align-items: center;
		padding: 0;
	}

	/* Toggle */
	.container-toggle-products {
		padding: 5rem 2rem;
	}

	.contain-products-toggle {
		justify-content: center;
	}

	/* Home-Grid */
	.container-home-grid {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		padding: 2rem 2rem;
	}

	.contain-home-grid-h1,
	.contain-home-grid-h2,
	.contain-home-grid-v {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding: 1rem;
	}

	.home-grid-content,
	.home-grid-content-v {
		width: 100%;
		padding: 6rem 0;
		justify-content: center;
		text-align: center;
		gap: 1.5rem;
	}

	/* Mensual-Promo */
	.container-mensual-promo {
		display: flex;
		flex-direction: column;
		gap: 3rem;
		padding: 8rem 2rem;
	}

	.mensual-promo-img {
		width: 100%;
	}

	.contain-mensual-promo {
		text-align: center;
	}

	/* Presents */
	.container-home-presents {
		display: flex;
		flex-direction: column;
		padding: 5rem 2rem;
	}

	.container-presents-img {
		width: 100%;
	}

	/* ABOUT */
	/* Description */
	.container-about-description {
		display: flex;
		padding: 5rem 2rem;
		flex-direction: column;
	}

	.container-about-description-2 {
		display: flex;
		flex-direction: column-reverse;
	}

	.contain-about-description {
		padding: 0;
		text-align: center;
	}

	.about-description-img {
		width: 100%;
	}

	/* Characteristics */
	.container-about-characteristics {
		padding: 5rem 2rem;
	}

	.contain-about-characteristics {
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}

	.characteristics-about-box {
		width: 100%;
	}

	/* Horizontal */
	.container-about-horizontal {
		padding: 10rem 2rem;
	}

	/* About-Contact */
	.container-about-contact {
		padding: 5rem 2rem;
	}

	.container-about-contact p {
		padding: 0;
	}

	/* About-Maps */
	.container-maps-about h3 {
		text-align: center;
	}

	.container-maps-about div {
		width: 100%;
	}

	/* CONTACT */
	/* Boxes */
	.container-boxes-contact {
		flex-direction: column;
		gap: 4rem;
		padding: 8rem 1rem;
	}

	.contain-boxes-contact {
		width: 100%;
	}

	/* Maps-Form */
	.container-maps-form {
		padding: 5rem 2rem;
		display: flex;
		flex-direction: column;
		gap: 3rem;
	}

	/* Social-Media */
	.container-contact-social-media h3 {
		text-align: center;
	}

	/* DATES */
	.contain-date-time {
		width: 100%;
	}

	.container-dates-form,
	.contain-dates-form {
		padding: 5rem 1rem;
	}
}
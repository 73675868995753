/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Quicksand', sans-serif;
}

:root {
	--primary-color: #02418E;
	--hover-primary: #2F81E6;
	--secundary-color: #E59500;
	--hover-secundary: #D5A021;
	--background-light: #F2F2F2;
	--background-dark: #001F4D;
	--light-text: #FFFFFF;
	--black-text: #202022;
	--footer-color: #001F4D;
	--white-color: #FAFAFA;
}


.container {
	margin: 0 auto;
	max-width: 95rem;
}

.container-banner-all {
	min-height: 40vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	color: var(--white-color);
	padding: 2rem 0;
	font-size: 3.5rem;
	font-family: "Playwrite NO", cursive !important;
}

/********************/
/*  DASHBOARD-MENU  */
/********************/

.container-dashboard-items {
	display: flex;
	flex-direction: column;
	padding: 0 0 1rem;
	min-height: 46vh;
	border-right: 1px solid #ccc;
}

.dashboard-item {
	display: flex;
	gap: 2rem;
	padding: .7rem;
	font-weight: 500;
	align-items: center;
	cursor: pointer;
	font-size: 1.2rem;
	color: var(--black-text);
	transition: all .4s ease;
}

.dashboard-sub-item:hover,
.dashboard-item:hover {
	color: var(--white-color);
	background-color: var(--primary-color);
}

.dashboard-item.active {
	background-color: var(--footer-color);
	color: var(--white-color);
}

.wish-icon {
	font-size: 1.5rem;
}

/********************/
/*    DASHBOARD     */
/********************/

/* Banner-All */
.profile {
	background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../images/banner-profile.webp');
	background-position: center;
	background-size: cover;
	position: fixed;
	width: 100%;
	z-index: 3;
}

.container-dashboard-title {
	font-size: 1.4rem;
	padding: 1rem 0;
	color: var(--black-text);
	background-color: #dfdfdf;
}

.container-dashboard {
	padding-top: 40vh;
	display: grid;
	grid-template-columns: 1fr 5fr;
	min-height: 56vh;
	height: 100%;
}

.contain-dashboard-cards {
	background-color: var(--background-light);
}

.container-dashboard-subitem {
	display: flex;
	flex-direction: column;
	padding-left: 2rem;
}

.dashboard-sub-item {
	display: flex;
	gap: 2rem;
	padding: .7rem;
	font-weight: 600;
	border-radius: 1rem;
	align-items: center;
	cursor: pointer;
	font-size: 1.2rem;
	color: var(--brown-color-2);
	border-bottom: 1px solid transparent;
	border-right: 1px solid transparent;
	transition: all .4s ease;
}

.contain-favorites-cards {
	display: flex;
	padding: 3rem;
	min-height: 65.7vh;
	background-color: var(--white-color-2);
}

.cards-contain-wish {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.sub-item-noactive {
	color: var(--brown-color-1);
}

.container-profile {
	display: flex;
	flex-direction: column;
	padding: 3rem;
	background-color: var(--white-color-2);
}

.container-orders {
	display: flex;
	flex-direction: column;
	padding: 3rem;
	background-color: var(--white-color-2);
}

.profile-info {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 28em;
}

.profile-info-text {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: .6rem;
	color: var(--brown-color-2);
}

.profile-info-text span {
	color: var(--brown-color-3);
	font-weight: 600;
}

.container-profile-handler {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 2rem 0;
	width: 13rem;
}

.profile-handler-link {
	display: flex;
	border: none;
	gap: .5rem;
	justify-content: center;
	background-color: var(--footer-color);
	cursor: pointer;
	padding: .5rem 1rem;
	color: var(--white-color);
	font-size: 1rem;
	transition: all .5s ease;
}

.logout-button {
	display: flex;
	border: none;
	gap: .5rem;
	background-color: var(--footer-color);
	cursor: pointer;
	padding: .5rem 1rem;
	color: var(--white-color);
	font-size: 1rem;
	justify-content: center;
	transition: all .5s ease;
}

.profile-handler-link:hover,
.logout-button:hover {
	background-color: var(--primary-color);
}

.container-wish-list {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	padding: 2rem;
}

/********************/
/*   ORDER-DETAIL   */
/********************/
.container-order-details {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding: 2rem 3rem;
}

.container-order-detail {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.contain-order-detail {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.order-detail-items {
	display: flex;
	flex-direction: column;
	gap: .5rem;
}

.detail-item-title {
	font-size: 1.2rem;
	font-weight: 500;
}

.container-order-products {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	overflow: hidden;
}

.contain-order-products {
	display: flex;
	gap: 3rem;
}

.order-product-detail-box {
	display: flex;
	width: 14rem;
	flex-direction: column;
	gap: 1rem;
	text-align: center;
	padding: 1rem;
}

.product-order-img {
	width: 100%;
	height: 12rem;
}

.product-order-img img {
	width: 100%;
	height: 100%;
}

/********************/
/*   EDIT-PROFILE   */
/********************/

.profile-info-edit {
	width: 18rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.input-edit {
	position: relative;
	height: 3rem;
	line-height: 3rem;
	width: 22rem;
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	/* Banner-All */
	.container-banner-all {
		font-size: 3rem;
	}

	/* Dashboard */
	.container-dashboard {
		display: flex;
		justify-content: space-between;
	}

	.profile-info {
		width: 100%;
	}

	.profile-info-text {
		display: flex;
		flex-direction: column;
		font-size: .9rem;
	}

	.profile-info-text span {
		font-size: 1rem;
		padding-left: 2rem;
	}

	.container-dashboard-items {
		width: 4.5rem;
	}

	.dashboard-item {
		gap: 0;
		align-items: center;
		justify-content: center;
	}

	.dashboard-item p {
		display: none;
	}

	/* Orders */
	.container-orders {
		padding: 3rem 1rem;
	}

	/* Wishlist */
	.container-wish-list {
		align-items: center;
		justify-content: center;
		padding: 2rem 1rem;
	}

	/* Edit-Profile */
	.profile-info-edit {
		width: 100%;
	}

	.input-edit {
		width: 100%;
	}
}

@media screen and (max-width: 400px) {
	.container-dashboard-items {
		width: 2.8rem;
	}

	.container-profile {
		padding: 3rem 1.5rem;
	}
}